import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#katar"> &gt; Poradnik(Katar)</a>
            <a href="/poradnik/katar/czym_jest_katar/" className="sel">
              {" "}
              &gt; Czym jest katar?
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_680919733.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Czym jest katar?</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <b>
                    Zapalenie śluzówki nosa, nieżyt nosa, infekcja wirusowa
                    górnych dróg oddechowych, obrzęk błony śluzowej nosa–
                    wszystkie te dolegliwości potocznie nazywamy katarem.
                    Istnieje kilka rodzajów kataru, np.: ostry (rozpoczynający
                    się nagle wywołany jest zazwyczaj infekcją wirusową),
                    przewlekły (np. spowodowany jest alergenami) oraz
                    epizodyczny (pojawia się pod wpływem nieswoistych czynników,
                    jak np. zmiana temperatury powietrza, dym papierosowy,
                    wysiłek fizyczny czy substancje drażniące).&nbsp;
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Katar infekcyjny – choroba, przed którą ciężko się ustrzec,
                    dopada nas najczęściej jesienią i zimą. Nie istnieją żadne
                    szczepionki chroniące przed katarem, a można się nim zarazić
                    bardzo szybko.&nbsp; Wystarczy uścisk dłoni w pracy,
                    sąsiedztwo kichającej osoby w autobusie czy tramwaju. I choć
                    zwykle lekceważymy klasyczny katar, pocieszając się
                    powiedzeniem „katar leczony trwa siedem dni, nieleczony
                    tydzień”, może on wywołać poważne powikłania, np.{" "}
                    <a
                      href="/slowniczek/#zapalenia_blony_sluzowej_nosa"
                      target="_blank"
                    >
                      stan zapalny zatok przynosowych
                    </a>
                    , zapalenie uszu, a także zapalenie oskrzeli. Ponadto często
                    występujące infekcje niszczą{" "}
                    <a href="/słowniczek/#blona_sluzowa_nosa" target="_blank">
                      błonę śluzową nosa
                    </a>
                    , przez co przestaje ona spełniać swoją rolę ochronną.
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Błona śluzowa górnych dróg oddechowych, a ściślej mówiąc
                    wyściełający je lepki śluz, stanowi rodzaj pułapki, w której
                    grzęzną szkodliwe wirusy. Śluz pełni funkcję izolacyjną,
                    blokuje przedostawanie się kurzu i pyłków roślinnych do
                    płuc. Śluzówka nosa, świetnie przygotowana do ochrony,
                    składa się z trzech warstw: nabłonka, błony podstawowej oraz
                    warstwy podśluzowej.&nbsp;
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="Czym jest katar i jak radzić sobie z zatkanym nosem? SUDAFED®"
                    src="/web/kcfinder/uploads/images/shutterstock_245721949.jpg"
                    style={{
                      height: "270px",
                      float: "left",
                      margin: "5px 10px 10px 0px",
                      "border-width": "2px",
                      "border-style": "solid",
                      width: "350px",
                    }}
                  />
                </p>
                <p
                  className="p4"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Pierwsza z nich wyposażona jest w rzęski, które przy
                  nagromadzeniu rozmaitych zanieczyszczeń oraz wirusów zaczynają
                  falować i&nbsp;bardzo szybko się poruszać (około 1000 razy na
                  minutę). Zachodzący proces odczuwamy jako „łaskotanie”,
                  swędzenie w nosie w następstwie czego kichamy (tym samym
                  usuwając zanieczyszczenia z nosa). Zdrowy nos skutecznie
                  blokuje rozmaite zanieczyszczenia. Gdy układ odpornościowy
                  jest osłabiony lub błona śluzowa nosa przesuszona, wirusy
                  przedostają się w głąb, przenikają do komórek nabłonka,
                  namnażają się i wywołują proces zapalny. W takiej sytuacji
                  nasz{" "}
                  <a href="/slowniczek/#uklad_immunologiczny" target="_blank">
                    układ immunologiczny
                  </a>{" "}
                  podejmuje szybką reakcję wysyłając w miejsce infekcji białe
                  krwinki (limfocyty). Naczynia krwionośne rozszerzają się,
                  powodując opuchliznę błony śluzowej (zatkanie nosa) i
                  intensywną produkcję śluzu. Konsystencja i kolor zalegającego
                  śluzu zmienia się wraz z rozwojem choroby. Początkowo
                  przybiera on formę przezroczystą i wodnistą. W kolejnym etapie
                  zwiększająca się liczba leukocytów (komórek zapalnych) zmienia
                  płyn w biało-zieloną lub żółtą, gęstą i trudną do usunięcia
                  maź. Początkowym fazom rozwoju przeziębienia, poza
                  zablokowaniem nosa i płynną wydzieliną, towarzyszą również
                  inne objawy takie, jak: uczucie rozbicia, osłabienie, ból
                  głowy.&nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Zablokowany nos utrudnia nam oddychanie, zaczynamy więc
                    oddychać przez usta. Zimne powietrze, znajdujące się w nim
                    różne zanieczyszczenia i chorobotwórcze drobnoustroje
                    trafiają do naszych dróg oddechowych Ważne jest więc
                    natychmiastowe podjęcie leczenia, udrożnienie górnych dróg
                    oddechowych, by znów móc oddychać przez nos. Pomocne mogą
                    okazać się tabletki lub aerozole do nosa Sudafed<sup>®</sup>
                    .{" "}
                    <a href="/slowniczek/#pseudoefedryna" target="_blank">
                      Pseudoefedryna
                    </a>{" "}
                    zawarta w{" "}
                    <a href="/produkty/sudafed_tabletki/" target="_blank">
                      tabletkach Sudafed<sup>®</sup>
                    </a>
                    , eliminuje uczucie ucisku w głowie i zatokach<sup>1</sup>{" "}
                    spowodowane obrzękiem błony śluzowej nosa i nagromadzną w
                    górnych drogach oddechowych wydzieliną. Natomiast{" "}
                    <a href="/slowniczek/#ksylometazolina" target="_blank">
                      ksylometazolina
                    </a>{" "}
                    znajdująca się w aerozolach do nosa{" "}
                    <a href="/produkty/sudafed_xylospray/" target="_blank">
                      Sudafed<sup>®</sup> XyloSpray
                    </a>{" "}
                    i{" "}
                    <a
                      href="http://l/produkty/sudafed_xylospray_ha"
                      target="_blank"
                    >
                      Sudafed<sup>®</sup> Xylospray HA
                    </a>{" "}
                    obkurcza naczynia krwionośne zlokalizowane w błonie śluzowej
                    nosa, dzięki czemu zmniejsza się jej obrzęk. Co więcej,{" "}
                    <a
                      href="http://l/produkty/sudafed_xylospray_ha"
                      target="_blank"
                    >
                      Sudafed<sup>®</sup> Xylospray HA
                    </a>{" "}
                    jako jedyny<sup>2</sup> posiada aż trzy substancje
                    nawilżające nos: glicerol, sorbitol i kwas hialuronowy (w
                    postaci soli sodowej), a dzięki braku konserwantów nie
                    podrażnia błony śluzowej nosa.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    1 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej
                    nosa.&nbsp;
                  </span>
                </p>
                <div>
                  <span
                    style={{
                      "font-size": "11px",
                      display: "inline-block",
                      "line-height": "normal",
                    }}
                  >
                    2 Wśród produktów zawierających Xylometazolinę/Oxymetazolinę
                    w postaci aerozolu do nosa, dopuszczonych do obrotu w Polsce
                    według Urzędowego Wykazu Produktów Leczniczych Dopuszczonych
                    do Obrotu na terytorium Rzeczypospolitej Polskiej, według
                    stanu na dzień 1 Lutego 2023 r.
                  </span>
                </div>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:&nbsp;</span>
                  </span>
                </p>
                <p
                  className="p5"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">
                      P. Dąbrowski, <i>Zapalenie górnych dróg oddechowych</i>,
                      [w:] „Przewodnik Lekarza”, 2002, 5, 3, s. 37–42.
                    </span>
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/katar/jak_prawidlowo_oczyszczac_nos_podczas_kataru"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg")',
                    }}
                  />
                  <span className="title">
                    Jak prawidłowo oczyszczać nos podczas kataru?
                  </span>
                </a>
                <a href="/poradnik/katar/poznaj_rodzaje_kataru" className="box">
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg")',
                    }}
                  />
                  <span className="title">Poznaj rodzaje kataru</span>
                </a>
                <a
                  href="/poradnik/katar/podejmij_szybka_walke_z_katarem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_152527238.jpg")',
                    }}
                  />
                  <span className="title">Podejmij szybką walkę z katarem</span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
